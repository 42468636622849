//=================================================
// Logo
//=================================================

.logo {
  display: block;

  img {
    max-height: 3.63rem;
  }
}
